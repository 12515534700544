import React from 'react';

class Footer extends React.Component {    
    render() {
      return (
        <div>
        <footer>
          <div className="container">
            <p className="copy_text">&copy; Copyright bossBNB 2021. All Rights Reserved</p>
          </div>
        </footer>

        <div className="overlay">
          <div className="overlayDoor"></div>
            <div className="overlayContent">
                <img src="img/dark_logo.png" alt="" />
                <p>Loading.....</p>
            </div>
          </div>
          <a style={{cursor:'pointer'}} id="return-to-top"><i className="fa fa-arrow-up" aria-hidden="true"></i></a>
        </div>
      );
    }
  }

  export default Footer;