import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Home from "./pages/home/home";
import './App.css';

class App extends React.Component {

  render() {
  return (
    <div className="App">
        <Router>
      <div>     

        {/*
          A <Switch> looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time
        */}
        <Switch>
          <Route exact path="/:referral?">
            <Home></Home>
          </Route>
         
        </Switch>
      </div>
    </Router>
    </div>
  );
  }
}

export default App;
