import React, { Component } from 'react';
import Frame from "../../components/frame";
import {withRouter} from "react-router-dom";
import { makeStyles, withStyles, styled } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import BossBNBV2 from "../../contracts/BossBNBV2.json";
import getWeb3 from "../../getWeb3";

const defaultAmountArr = ['1','2','4','6','10'];

class Home extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        loading:true,
        web3: null,
        accounts: null,
        contract: null,
        contractAddress: null,
        sponsor_address: (this.props.match.params.referral != 'undefined') ? this.props.match.params.referral : '',
        userInfo:{},
        TotalBonus:0,
        totalWithdrawn:0,
        totalDeposits:0,
        totalInvested:0,
        totalUsers:0,
        networkMain:false,
        YourAddress: '-',
        YourAddressfull: null,
        amountBNB:null,
        user_referer:'-',
        balance:null,
        adminFee:null,
        adminFee_percentage:5,
        reinvest_percentage:0,
        withdrwal_percentage:0,
        GetDownlineIncomeByUserId:0,
        GetUplineIncomeByUserId:0,
        communityLevels:[],
        sponsorList:[],
        poolDepositTotal:0,
        poolDeposit:0,
        poolQualifierCount:0,
        GetPoolIncome:0,
      }
    }

    componentDidMount = async () => {
      try {
        // Get network provider and web3 instance.
        const web3 = await getWeb3();
  
        // Use web3 to get the user's accounts.
        setInterval(async()=>{
          const accounts = await web3.eth.getAccounts();
         
          if(this.state.accounts !== accounts[0]){
          // Get the contract instance.
          const networkId = await web3.eth.net.getId();
          //alert(networkId);
          //if(networkId !== 56) throw "Please connect Mainnet"; 
          const deployedNetwork = BossBNBV2.networks[networkId];
          const instance = new web3.eth.Contract(
            BossBNBV2.abi,
            deployedNetwork && deployedNetwork.address,
          );   
          
          this.setState({ web3, accounts:accounts[0], contract: instance, contractAddress:deployedNetwork.address}, this.fetchData);
          }
       },1000);

      } catch (error) {
        // Catch any errors for any of the above operations.
        // alert(
        //   error
        // );
        this.setState({networkMain:false});
        console.error(error);
      }
    };

    fetchData = async() => {
      const { accounts, contract } = this.state;

      console.log(this.state.contractAddress);

      let YourAddress = accounts.slice(0, 10) + '.....' + accounts.slice(accounts.length-5, accounts.length);
      let YourAddressfull = accounts;
      let userInfo = await contract.methods.users(accounts).call();
      userInfo.amount = this.state.web3.utils.fromWei(userInfo.amount, 'ether');
      userInfo.amount = parseFloat(userInfo.amount).toFixed(5);
      userInfo.referrerBonus = this.state.web3.utils.fromWei(userInfo.referrerBonus, 'ether');
      userInfo.referrerBonus = parseFloat(userInfo.referrerBonus).toFixed(5);
      userInfo.totalWithdrawn = this.state.web3.utils.fromWei(userInfo.totalWithdrawn, 'ether');
      userInfo.totalWithdrawn = parseFloat(userInfo.totalWithdrawn).toFixed(5);
      let user_referer = '-';
      if(userInfo.checkpoint != undefined && userInfo.checkpoint > 0){
        user_referer = userInfo.referrer;
        user_referer = user_referer.slice(0, 10) + '.....' + user_referer.slice(user_referer.length-5, user_referer.length);
      }
      // start modified on 07-09-2021
      let GetPoolIncome = await contract.methods.GetPoolIncome(accounts).call();
      GetPoolIncome = this.state.web3.utils.fromWei(GetPoolIncome[0], 'ether');
      GetPoolIncome = parseFloat(GetPoolIncome).toFixed(5);
      // end modified on 07-09-2021
      let TotalBonus = await contract.methods.TotalBonus(accounts).call();
      TotalBonus = this.state.web3.utils.fromWei(TotalBonus, 'ether');
      TotalBonus = parseFloat(TotalBonus).toFixed(5);
      let totalWithdrawn = await contract.methods.totalWithdrawn().call();
      totalWithdrawn = this.state.web3.utils.fromWei(totalWithdrawn, 'ether');
      totalWithdrawn = parseFloat(totalWithdrawn).toFixed(5);
      let totalDeposits = await contract.methods.totalDeposits().call();
      totalDeposits = parseFloat(totalDeposits).toFixed(5);
      let totalInvested = await contract.methods.totalInvested().call();
      totalInvested = this.state.web3.utils.fromWei(totalInvested, 'ether');
      totalInvested = parseFloat(totalInvested).toFixed(5);
      let totalUsers = await contract.methods.totalUsers().call();
      // start modified on 07-09-2021
      let poolDepositTotal = await contract.methods.poolDepositTotal().call();
      poolDepositTotal = this.state.web3.utils.fromWei(poolDepositTotal, 'ether');
      poolDepositTotal = parseFloat(poolDepositTotal).toFixed(5);
      let poolDeposit = await contract.methods.poolDeposit().call();
      poolDeposit = this.state.web3.utils.fromWei(poolDeposit, 'ether');
      poolDeposit = parseFloat(poolDeposit).toFixed(5);
      let poolQualifierCount = await contract.methods.poolQualifierCount().call();
      // end modified on 07-09-2021
      let balance = TotalBonus;
      let adminFee = balance*this.state.adminFee_percentage/100;
      balance = balance - adminFee;


      let getEligibleWithdrawal = await contract.methods.getEligibleWithdrawal(accounts).call();
      let reinvest_percentage = getEligibleWithdrawal.reivest;
      let withdrwal_percentage = getEligibleWithdrawal.withdrwal;

      let singleUplineBonusTaken = this.state.web3.utils.fromWei(userInfo.singleUplineBonusTaken, 'ether');
      let singleDownlineBonusTaken = this.state.web3.utils.fromWei(userInfo.singleDownlineBonusTaken, 'ether');

      let GetDownlineIncomeByUserId = await contract.methods.GetDownlineIncomeByUserId(accounts).call();
      GetDownlineIncomeByUserId = this.state.web3.utils.fromWei(GetDownlineIncomeByUserId, 'ether');
      GetDownlineIncomeByUserId = GetDownlineIncomeByUserId - singleDownlineBonusTaken;
      GetDownlineIncomeByUserId = parseFloat(GetDownlineIncomeByUserId).toFixed(5);

      let GetUplineIncomeByUserId = await contract.methods.GetUplineIncomeByUserId(accounts).call();
      GetUplineIncomeByUserId = this.state.web3.utils.fromWei(GetUplineIncomeByUserId, 'ether');
      GetUplineIncomeByUserId = GetUplineIncomeByUserId - singleUplineBonusTaken;
      GetUplineIncomeByUserId = parseFloat(GetUplineIncomeByUserId).toFixed(5);

      this.setState({ 
        userInfo,
        TotalBonus,
        totalWithdrawn,
        totalDeposits,
        totalInvested,
        totalUsers,
        poolDepositTotal,
        poolDeposit,
        poolQualifierCount,
        GetPoolIncome,
        //networkMain:true,
        YourAddress:YourAddress,
        YourAddressfull:YourAddressfull,
        user_referer:user_referer,
        balance,
        adminFee,
        reinvest_percentage,
        withdrwal_percentage,
        GetUplineIncomeByUserId,
        GetDownlineIncomeByUserId,
        loading:false
      },async()=>{
        const { accounts, contract } = this.state;
        let getEligibleLevelCountForUpline = await contract.methods.getEligibleLevelCountForUpline(accounts).call();
        let uplineCount = getEligibleLevelCountForUpline.uplineCount;
        let downlineCount = getEligibleLevelCountForUpline.downlineCount;
        let communityLevels = [];
        let upline_users = [];
        let downline_users = [];
        let current_user = accounts;
        let userInfo = await contract.methods.users(current_user).call();
        for(let i=1;i<=uplineCount;i++){          
          if(current_user == userInfo.singleUpline) continue;
          current_user = userInfo.singleUpline;
          let emptyAddress = /^0x0+$/.test(current_user);
          if(emptyAddress) continue;
          userInfo = await contract.methods.users(current_user).call();
          let investment = this.state.web3.utils.fromWei(userInfo.amount, 'ether');
          let income = (investment/100).toFixed(5);
          investment = parseFloat(investment).toFixed(5);
          upline_users.push({level:i,levelText:'Upline-'+i,type:'upline',username:current_user,investment:investment,income:income});
        }
        upline_users.sort((a, b) => b.level>a.level? 1 : -1);
        upline_users.map(function(val, index){
          communityLevels.push(val);
        });
        let currentUserInfo = await contract.methods.users(accounts).call();
        let investment = this.state.web3.utils.fromWei(currentUserInfo.amount, 'ether');
        let income = (investment/100).toFixed(5);
        income = (0).toFixed(5);
        investment = parseFloat(investment).toFixed(5);
        let main_user = {level:0,levelText:'You',type:'main_user',username:accounts,investment:investment,income:income};
        communityLevels.push(main_user);
        current_user = accounts;
        userInfo = await contract.methods.users(current_user).call();
        for(let i=1;i<=downlineCount;i++){
          if(current_user == userInfo.singleDownline) continue;
          current_user = userInfo.singleDownline;
          let emptyAddress = /^0x0+$/.test(current_user);
          if(emptyAddress) continue;
          userInfo = await contract.methods.users(current_user).call();
          let investment = this.state.web3.utils.fromWei(userInfo.amount, 'ether');
          let income = (investment/100).toFixed(5);
          investment = parseFloat(investment).toFixed(5);
          downline_users.push({level:i,levelText:'Downline-'+i,type:'downline',username:current_user,investment:investment,income:income});
        }
        downline_users.map(function(val, index){
          communityLevels.push(val);
        });

        let sponsorList = [];
        let count = 0;
        for(let i=0;i<6;i++){
          let referral_stage = await contract.methods.referral_stage(accounts,i).call();
          let _investment = this.state.web3.utils.fromWei(referral_stage._investment, 'ether');
          //let ref_bonuses = await contract.methods.ref_bonuses(i).call();
          let percentage_amount = this.state.web3.utils.fromWei(referral_stage._bonus, 'ether'); //(_investment*ref_bonuses/100).toFixed(5);
          _investment = parseFloat(_investment).toFixed(5);
          percentage_amount = parseFloat(percentage_amount).toFixed(5);
          let _noOfUser = referral_stage._noOfUser;
          
          if(i == 0){
            count = _noOfUser;
          }
          let status = '';
          if(i>1 && count<4){
            status = 'Unqualified';
          }
          sponsorList.push({status:status,level:i+1,_investment:_investment,_noOfUser:_noOfUser,percentage_amount:percentage_amount});
        }
        

        this.setState({communityLevels, sponsorList});
        // console.log(communityLevels);
        
        // console.log(sponsorList);
         
      });     
    }

    doJoinNow = async () => {
      //const weiValue = this.state.web3.utils.toWei('1', 'ether');
      //const etherValue = this.state.web3.utils.fromWei('1000000000000000000', 'ether');
      const { accounts, contract } = this.state;
      let sponsor_address = this.state.sponsor_address;
      let userInfo = await contract.methods.users(accounts).call();
      if(userInfo.checkpoint != undefined && userInfo.checkpoint > 0){
        sponsor_address = userInfo.referrer;
      }
      else if(!sponsor_address){
        this.props.enqueueSnackbar("Sponsor Address is required!",{ variant: 'error' })
        this.setState({sponsor_addressError:true});
        return false;
      }

      if(!this.state.amountBNB){
        this.props.enqueueSnackbar("Amount is required!",{ variant: 'error' })
        this.setState({amountError:true});
        return false;
      }
      
      let balance = await this.state.web3.eth.getBalance(this.state.accounts);
      let balanceEthVal = this.state.web3.utils.fromWei(balance, 'ether');
      let amountBNB = this.state.amountBNB;
      if(sponsor_address){
        if(balanceEthVal >= amountBNB){
          try {
            this.setState({loading:true});
            this.state.contract.events.NewDeposit((err, eventResult) => {
              console.log('eventResult',eventResult);
              if (!err) {
                let uerAddress = this.state.accounts;
                if(eventResult.returnValues[0] == uerAddress){
                  this.fetchData();
                  this.setState({loading:false});
                  this.props.enqueueSnackbar("Joined Successfully!",{ variant: 'success' });                
                }              
              }else{
                console.log(err);
                this.setState({loading:false});
                this.props.enqueueSnackbar('Some Network Error Occurred!');              
              }
            });

            let weiValue = this.state.web3.utils.toWei(amountBNB, 'ether');
            let invest = await this.state.contract.methods.invest(sponsor_address).send(
              {
                from: this.state.accounts,
                value:weiValue
              }
            );
            console.log(invest);
          }
          catch(err) {
            this.setState({loading:false});
            console.log(err);
          }
          
        }else{
          this.setState({loading:false});
          this.props.enqueueSnackbar("Insufficient BNB Balance!",{ variant: 'error' });          
        }        
      }else{
        this.setState({loading:false});
        this.props.enqueueSnackbar("Please enter sponsor address!",{ variant: 'error' });        
      }
    }

    doWithdrawal = async () => {
      if(this.state.TotalBonus > 0){
        this.setState({loading:true});
        this.state.contract.events.Withdrawn((err, eventResult) => {
          console.log('eventResult',eventResult);
          console.log('err',err);
          if (!err) {
            let uerAddress = this.state.accounts;
            if(eventResult.returnValues[0] == uerAddress){
              this.fetchData();
              this.setState({loading:false});
              this.props.enqueueSnackbar("Withdrawal Successfully!",{ variant: 'success' });                
            }              
          }else{
            console.log(err);
            this.setState({loading:false});
            this.props.enqueueSnackbar('Some Network Error Occurred!');              
          }        
        });

        let withdrawal = await this.state.contract.methods.withdrawal().send(
          { 
            from: this.state.accounts
          }
        );
        console.log('withdrawal',withdrawal); 
      }else{
        this.props.enqueueSnackbar('Insufficient balance!');
      }
           
    }

    render() {
      let this2 = this;
      return (
        <Frame withFooter={true}>
           <img className="particle_img" src="img/particle_img.png" alt="" />

           <div className="pb_40 pt_40">
            <div className="container">
              <div className="banner_inner">
                <img className="banner_logo pt_40" src="img/white_big_logo.png" alt="" />
              </div>
            </div>
          </div>
  
          <div className="pb_40 pt_40">
            <div className="md_container">
              {this.state.loading ? (
                <div class="load_bg">
                  <span class="load"></span>
                </div>
              ) : (null)}
             
              <div className="box_outer mb_50">
                <div className="all_heading text-center">
                  <h2>Join BossBNB Platform</h2>
                </div>
                <div className="border-dashed">                
                  <div className="border-inner padding_50">
                    <div className="form-group mb_20">
                      <div className="row">
                        <div className="col-md-8 col-lg-8 col-sm-8">
                          {(this.state.userInfo.checkpoint != undefined && this.state.userInfo.checkpoint > 0) ?(
                            <input className="cus_input sm_device_m_b" type="text" placeholder="Sponsor Address"
                              readOnly
                              value={this.state.user_referer}
                              onChange={(e)=>this.setState({sponsor_address:e.target.value})}
                            />
                          ):(
                            <input className="cus_input sm_device_m_b" type="text" placeholder="Sponsor Address"
                              value={this.state.sponsor_address}
                              onChange={(e)=>this.setState({sponsor_address:e.target.value})}
                            />
                          )}
                        </div>
                        <div className="col-md-4 col-lg-4 col-sm-4">
                          <button onClick={this.doJoinNow} className="join_now_btn">Join Now</button>
                        </div>
                      </div>
                    </div>
                    <ul className="trx_btn">
                      {defaultAmountArr.map((amount,key) => {return(
                        <li key={key}>
                          <button className={this.state.amountBNB==amount?'active':'inactive'} 
                            onClick={e => this.setState({amountBNB:amount})}>
                            {amount+' BNB'}
                          </button>
                        </li>
                      )                              
                      })}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="all_heading text-center">
                <h2>Personal Details</h2>
              </div>
              <div className="mb_10">
                <div className="row cus_row">
                  <div className="col-md-4 col-sm-4 col-6">
                    <div className="Personal_Details_inner Personal_bg">
                      <h4>Total Deposit</h4>
                      <h5>{this.state.userInfo.amount} BNB</h5>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-6">
                    <div className="Personal_Details_inner">
                      <h4>Total Withdrawn</h4>
                      <h5>{this.state.userInfo.totalWithdrawn} BNB</h5>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-12">
                    <div className="Personal_Details_inner">
                      <h4>Referred By</h4>
                      <h5>{this.state.user_referer}</h5>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <div className="container mt_20 mb_20">
              <div className="small_heading">
                <h5>ID - {this.state.YourAddress}</h5>
              </div>
              <div className="row cus_row">
                <div className="col-md-3 col-sm-3 col-6">
                  <div className="id_inner">
                    <h4>Community Level</h4>
                    <p>Upline Income</p>
                    <h5>{this.state.GetUplineIncomeByUserId} BNB</h5>
                  </div>
                </div>
                <div className="col-md-3 col-sm-3 col-6">
                  <div className="id_inner">
                    <h4>Community Level</h4>
                    <p>Downline Income</p>
                    <h5>{this.state.GetDownlineIncomeByUserId} BNB</h5>
                  </div>
                </div>
                <div className="col-md-3 col-sm-3 col-6">
                  <div className="id_inner">
                    <h4>Affiliate Level</h4>
                    <p>Sponsor Income</p>
                    <h5>{this.state.userInfo.referrerBonus} BNB</h5>
                  </div>
                </div>
                <div className="col-md-3 col-sm-3 col-6">
                  <div className="id_inner">
                    <h4>Pool</h4>
                    <p>Pool Income</p>
                    <h5>{this.state.GetPoolIncome} BNB</h5>
                  </div>
                </div>
              </div>
            </div>

            <div className="md_container">

              <div className="all_heading text-center">
                <h2>Global Details</h2>
              </div>

              <div className="mb_30">
                <div className="row cus_row">
                  <div className="col-md-6 col-sm-6 col-12">
                    <div className="Personal_Details_inner">
                      <h4>Total Pool</h4>
                      <h5>{this.state.poolDepositTotal} BNB</h5>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-6">
                    <div className="Personal_Details_inner">
                      <h4>Daily Pool</h4>
                      <h5>{this.state.poolDeposit} BNB / {this.state.poolQualifierCount}</h5>
                    </div>
                  </div>               
                </div>                
              </div>

              <div className="mb_30">
                <div className="row cus_row">
                  <div className="col-md-4 col-sm-4 col-6">
                    <div className="Personal_Details_inner">
                      <h4>Total Community</h4>
                      <h5>{this.state.totalUsers}</h5>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-6">
                    <div className="Personal_Details_inner">
                      <h4>Total Invested</h4>
                      <h5>{this.state.totalInvested} BNB</h5>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-12">
                    <div className="Personal_Details_inner">
                      <h4>Total Withdrawal</h4>
                      <h5>{this.state.totalWithdrawn} BNB</h5>
                    </div>
                  </div>                  
                </div>                
              </div>              

              <div className="box_outer mb_50">
                <div className="all_heading text-center">
                  <h2>Withdrawal</h2>
                </div>
                <div className="border-dashed">
                  <div className="border-inner padding_50">
                    <div className="row">
                      <div className="col-md-12 col-lg-12 col-sm-12">
                        <div className="form-group mb_20">
                          <label className="white_label">Available BNB ({this.state.adminFee_percentage} % Admin Fee: {this.state.adminFee})</label>
                          <input className="cus_input" type="text" readOnly value={this.state.balance} />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-lg-6 col-sm-6">
                        <div className="form-group mb_20">
                          <label className="white_label">Reinvestment Amount (BNB)</label>
                          <input className="cus_input" type="text" readOnly 
                          value={parseFloat(this.state.reinvest_percentage*this.state.balance/100).toFixed(5)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-6 col-sm-6">
                        <div className="form-group mb_20">
                          <label className="white_label">Withdrawable Amount (BNB)</label>
                          <input className="cus_input" type="text" readOnly
                          value={parseFloat(this.state.withdrwal_percentage*this.state.balance/100).toFixed(5)}
                          />
                        </div>
                      </div>
                      
                    </div>
                    <div className="form-group mb_20">
                      <p className="white_label mb-0">Reinvestment percentage: <strong>{this.state.reinvest_percentage}%</strong></p>
                      <p className="white_label">Withdrawable percentage: <strong>{this.state.withdrwal_percentage}% </strong></p>
                    </div>
                    <button onClick={this.doWithdrawal} className="join_now_btn mt_20">Withdrawal</button>
                  
                  </div>
                </div>
              </div>
            </div>

            

            <div className="mt_40">
              <div className="md_container">
                <div className="all_heading text-center">
                  <h2>Community Level</h2>
                </div>
                <div className="table-responsive mb_50">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Level</th>
                        <th>User ID</th>
                        <th>Investment</th>
                        <th>Income</th>
                      </tr>
                    </thead>
                    <tbody className="">                      
                      {this.state.communityLevels.length ? (
                        this.state.communityLevels.length>0 ? (
                          this.state.communityLevels.map(function(val, index){
                            let class_name = 'lebel_'+val.level;
                            if(val.level == 0){
                              class_name = 'current_user';
                            }
                            return (
                              <tr key={`cl${index}`} className={class_name}>
                                <td style={{width:'120px'}}>{val.levelText}</td>
                                <td style={{width:'50%'}}>{val.username}</td>
                                <td>{val.investment} BNB</td>
                                <td>
                                {val.income} BNB
                                </td>
                              </tr>
                            )
                          })
                        ):(null)
                      ) : (
                      <tr>
                        <td colSpan="4" className="text-center">No Data Available!</td>
                      </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                <div className="all_heading text-center">
                  <h2>My sponsor List</h2>
                </div>
                <div className="table-responsive mb_50">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th> Level </th>
                        <th> Count </th>
                        <th> Invest Amount </th>
                        <th> Income </th>
                      </tr>
                    </thead>
                    <tbody>
                    {this.state.sponsorList.length ? (
                        this.state.sponsorList.length>0 ? (
                          this.state.sponsorList.map(function(val, index){
                            let class_name = 'lebel_'+val.level;
                            if(val.level == 0){
                              class_name = 'current_user';
                            }
                            return (
                              <tr key={`sl${index}`} className={class_name}>
                                <td style={{width:'120px'}}>
                                  Level-{val.level}
                                </td>
                                <td>{val._noOfUser}</td>
                                <td>{val._investment} BNB</td>
                                <td>{val.percentage_amount} BNB  {val.status?(`(${val.status})`):(null)}</td>
                              </tr>
                            )
                          })
                        ):(null)
                      ) : (
                      <tr>
                        <td colSpan="4" className="text-center">No Data Available!</td>
                      </tr>
                      )}
                    </tbody>
                    </table>
                </div>

                <div className="border-dashed">
                  <div className="border-inner padding_50 text-center">
                    <div className="referal_inner">
                      <h3>Your Referral Link</h3>
                      <h4 className="word-break">https://bossbnb.com/{this.state.YourAddress}</h4>
                        <CopyToClipboard text={`https://bossbnb.com/${this.state.YourAddressfull}`}
                          onCopy={() => this.props.enqueueSnackbar("Copied Successfully!",{ variant: 'info' })
                          }>
                          <button className="copy_btn">Copy</button>
                        </CopyToClipboard>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>
          
          <div className="mb_40">
            <div className="container">
              <div className="all_heading text-center">
                <h2>Plan PDF</h2>
              </div>
              <ul class="pdf_downlad_ul">
                <li>
                  <a target="_blank" href="pdf/BossBNB.com-Japanese.pdf">
                    <span><i class="fa fa-file-pdf-o"></i></span>
                    <h5>Japanese PDF</h5>
                    <p>Download  <i class="fa fa-download"></i></p>
                  </a>
                </li>
                <li>
                  <a target="_blank" href="pdf/BossBNB.com-Russian.pdf">
                    <span><i class="fa fa-file-pdf-o"></i></span>
                    <h5>Russian PDF</h5>
                    <p>Download  <i class="fa fa-download"></i></p>
                  </a>
                </li>
                <li>
                  <a target="_blank" href="pdf/BossBNB.com-German.pdf">
                    <span><i class="fa fa-file-pdf-o"></i></span>
                    <h5>German PDF</h5>
                    <p>Download  <i class="fa fa-download"></i></p>
                  </a>
                </li>
                <li>
                  <a target="_blank" href="pdf/BNB.com-Korean.pdf">
                    <span><i class="fa fa-file-pdf-o"></i></span>
                    <h5>Korean PDF</h5>
                    <p>Download  <i class="fa fa-download"></i></p>
                  </a>
                </li>
                <li>
                  <a target="_blank" href="pdf/BossBNB.com-Chinese.pdf">
                    <span><i class="fa fa-file-pdf-o"></i></span>
                    <h5>Chinese PDF</h5>
                    <p>Download  <i class="fa fa-download"></i></p>
                  </a>
                </li>
                <li>
                  <a target="_blank" href="pdf/BossBNB.com-Indonesian.pdf">
                    <span><i class="fa fa-file-pdf-o"></i></span>
                    <h5>Indonesian PDF</h5>
                    <p>Download  <i class="fa fa-download"></i></p>
                  </a>
                </li>
                <li>
                  <a target="_blank" href="pdf/BossBNB.com-Tagalog.pdf">
                    <span><i class="fa fa-file-pdf-o"></i></span>
                    <h5>Tagalog PDF</h5>
                    <p>Download  <i class="fa fa-download"></i></p>
                  </a>
                </li>
                <li>
                  <a target="_blank" href="pdf/BossBNB.com-Vietnamese.pdf">
                    <span><i class="fa fa-file-pdf-o"></i></span>
                    <h5>Vietnamese PDF</h5>
                    <p>Download  <i class="fa fa-download"></i></p>
                  </a>
                </li>
                <li>
                  <a target="_blank" href="pdf/BossBNB.com-French.pdf">
                    <span><i class="fa fa-file-pdf-o"></i></span>
                    <h5>French PDF</h5>
                    <p>Download  <i class="fa fa-download"></i></p>
                  </a>
                </li>
               
              </ul>
            </div>
          </div>

          <div className="mb_40">
            <div className="container">
              <div className="all_heading text-center">
                <h2>Plan Video</h2>
              </div>
               <div class="video_inner">
                <a target="_blank" class="popup-youtube" href="video/video.mp4">
                    <img class="video-icon" src="img/video_bg.jpg" alt="" />
                  </a>
               </div>
            </div>
               
              </div>


        </Frame>
      );
    }
  }

  const useStyles = {
    root: {
      flexGrow: 1
    }
  }

  export default withRouter(withStyles(useStyles)(withSnackbar(Home)));
  //export default withRouter(Home);